<template>
  <div class="shop">
    <!-- 輪播圖 -->
    <div class="tw-carousel mb-3" v-if="pictureLinks.length > 0">
      <Carousel
        :autoplay="5000"
        :items-to-show="currentViewSize.width >= 768 ? 3 : 1"
        :wrap-around="true"
      >
        <Slide v-for="data in pictureLinks" :key="data.id">
          <div class="carousel__item">
            <div
              class="pointer carousel-img"
              :style="`background: url(${data.imgUrl}) no-repeat center center; background-size: contain;`"
              @click="carouselUrl(data)"
            ></div>
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
    <!-- 下方內容 -->
    <div class="shop-content">
      <!-- 分類圖 -->
      <div class="category-img" v-if="currentCategoryBanner">
        <Image
          :imageHash="currentCategoryBanner"
          :lazy="false"
          v-if="currentCategoryBanner"
          :alt="currentCategoryBanner"
        ></Image>
      </div>
      <!-- 內容 -->
      <div class="tw-container">
        <!-- <= 919px 的分類標籤 -->
        <div class="category-lable pointer" @click="showCategoryList">
          分類選單
        </div>
        <!-- 分類與子標題 -->
        <div>
          <!-- 分類名 -->
          <div class="d-flex align-items-center mb-2">
            <img
              src="@/assets/website-use/poke_R.png"
              alt="poke_r"
              style="width: 40px; height: 40px"
            />
            <span class="tw-text-size20 fw-bolder">{{
              currentCategoryName
            }}</span>
          </div>
          <!-- 子標題 -->
          <div v-if="currentCategorySubtitle">
            <i class="bi bi-arrow-return-right"></i>
            <span class="mt-2 text-secondary fw-bolder tw-text-size14">
              {{ currentCategorySubtitle }}
            </span>
          </div>
        </div>
        <!-- 商品與分類內容 -->
        <div class="merh-area">
          <!-- 分類區塊 -->
          <div id="CategoryBlock" class="category-block" @click="removeClass">
            <!-- 搜尋 -->
            <div>
              <div class="search input-group my-2">
                <input
                  type="text"
                  class="form-control d-inline-block"
                  placeholder="輸入欲搜尋商品名稱"
                  v-model="search"
                  @click.stop=""
                />
                <button
                  class="btn btn-light border"
                  type="button"
                  id="button-addon2"
                  @click.stop="getMerchandises('search')"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
            <!-- 分類列表 -->
            <ul class="category-area">
              <li
                class="pointer"
                v-for="data in categories"
                :key="data.id"
                @click="changeCategory(data)"
              >
                <p>{{ data.name }}</p>
                <p class="subtitle" v-if="data.subtitle">
                  <i class="bi bi-arrow-return-right"></i>{{ data.subtitle }}
                </p>
              </li>
              <li class="pointer border-0" @click="changeCategory(0)">
                未分類
              </li>
            </ul>
          </div>
          <!-- 商品區塊 -->
          <template v-if="!areaLoading">
            <div class="merh-card-area row" v-if="merchandiseList.length > 0">
              <div
                :id="`Merchandise${data.id}`"
                class="col-xl-3 col-md-4 col-6 p-0 mb-3 px-1"
                v-for="data in merchandiseList"
                :key="data.id"
              >
                <!-- 商品卡片 -->
                <div class="merch-card p-2" @click="goToOrder(data)">
                  <div
                    class="deadline-area fw-bolder text-break"
                    v-if="data.deadLineTime"
                  >
                    <CountDown
                      :id="data.id"
                      :time="data.deadLineTime"
                    ></CountDown>
                  </div>
                  <!-- 圖 -->
                  <Image
                    class="merch-img mb-2"
                    style="object-fit: contain"
                    :imageHash="data.frontImgHash"
                    v-if="data.frontImgHash"
                    :alt="data.frontImgHash"
                  ></Image>
                  <img
                    class="merch-img mb-2"
                    src="@/assets/other-images/noImg.png"
                    v-else
                  />
                  <!-- 分隔線 -->
                  <div
                    class="d-flex justify-content-between align-items-center mb-2"
                  >
                    <hr class="m-0 p-0 w-100" />
                    <!-- 販售狀態 -->
                    <div class="fw-bolder text-center d-inline-block">
                      <p
                        class="text-success alert-success p-1"
                        v-if="data.sellState === '可預購'"
                        style="width: 60px"
                      >
                        可預購
                      </p>
                      <p
                        class="text-primary alert-primary p-1"
                        v-else-if="data.sellState === '僅供現貨'"
                        style="width: 80px"
                      >
                        僅供現貨
                      </p>
                      <p
                        class="text-danger alert-danger p-1"
                        v-else
                        style="width: 50px"
                      >
                        限量
                      </p>
                    </div>
                  </div>
                  <!-- 內容 -->
                  <div class="tw-text-size14">
                    <ul>
                      <!-- 商品名 -->
                      <li :title="data.name" class="fw-bolder mb-1 merch-name">
                        {{ data.name }}
                      </li>
                      <!-- 商品標籤 -->
                      <li
                        class="mb-2 text-success fw-bolder merch-lable"
                        :title="data.label"
                      >
                        {{ data.label }}
                      </li>
                      <!-- 售價 -->
                      <li class="mb-2">
                        <span v-if="merchCardShowPriceMode === 'normal'">
                          <span v-if="data.lowerPrice == data.higherPrice">
                            ${{ data.lowerPrice }}
                          </span>
                          <span v-else>
                            ${{ data.lowerPrice }} ~ ${{ data.higherPrice }}
                          </span>
                        </span>
                        <span v-else>
                          <span v-if="data.lowerPrice == data.higherPrice">
                            ${{ data.higherPrice }}
                          </span>
                          <span v-else>
                            <del class="me-2">${{ data.higherPrice }}</del>
                            <span
                              class="fw-bolder text-danger"
                              style="font-size: 17px"
                            >
                              ${{ data.lowerPrice }}
                            </span>
                          </span>
                        </span>
                      </li>
                      <!-- 成團數 / 庫存 -->
                      <li class="d-flex fw-bolder mb-2">
                        <span
                          class="border border-danger text-danger me-2"
                          v-if="data.startSaleQuantity"
                          style="padding: 2px"
                        >
                          {{ data.startSaleQuantity }}單成團
                        </span>
                        <span
                          class="border border-primary text-primary"
                          style="padding: 2px"
                          v-if="showStock"
                        >
                          庫存: {{ data.remainingStock }}
                        </span>
                      </li>
                      <!-- 最底下資訊 -->
                      <div class="info-area text-secondary">
                        <!-- 熱賣 -->
                        <p
                          class="fw-bolder"
                          v-if="
                            merchCardShowSellQuantity !== 'doNotShow' &&
                            data.hotQuantity >= merchCardShowSellQuantity
                          "
                        >
                          已售出: {{ data.hotQuantity }}
                        </p>
                        <!-- 到貨時間 -->
                        <p
                          class="text-break d-inline-block fw-bolder"
                          v-if="merchCardShowArrivalTime && data.arrivalTime"
                        >
                          到貨:{{ data.arrivalTime }}
                        </p>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="w-100 d-flex justify-content-center align-items-center"
              v-else
            >
              <p class="alert alert-danger fw-bolder d-inline-block">
                目前分類未有任何商品呦 ^^
              </p>
            </div>
          </template>
          <!-- 請稍後字樣 -->
          <div
            class="d-flex justify-content-center align-items-center w-100"
            v-else
          >
            <AreaLoading></AreaLoading>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
// components
import CountDown from '@/components/tools/CountDown.vue'
// api
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'
// methos
import { handleImageHash2Url } from "@/methods/mixins/imageHash2Url"

export default {
  components: {Carousel, Slide, Pagination, Navigation, CountDown},
  data() {
    return {
      storeId: 0,
      serverToken: '',
      storeInfo: {},
      pictureLinks: [],
      areaLoading: true,
      // 紀錄目前視窗寬高
      currentViewSize: {
        width: 0,
        height: 0,
      },
      participantInfo: {},
      // 商品分類
      categories: [],
      currentCategoryId: 0,
      currentCategoryName: '未分類',
      currentCategorySubtitle: '',
      currentCategoryBanner: null,
      // 商品
      merchandiseList: [],
      // 搜尋
      search: '',
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
    this.getParticipantInfo()
  },
  mounted() {
    // 紀錄目前視窗寬高
    this.currentViewSize.width = window.innerWidth;
    this.currentViewSize.height = window.innerHeight;
    // 監聽
    window.onresize = () => {
      this.currentViewSize.width = window.innerWidth;
      this.currentViewSize.height = window.innerHeight;
    };
  },
  unmounted() {
    this.breakOff()
  },
  watch: {
    currentCategoryId(val, oldVal) {
      if (val !== oldVal && val !==-1) {
        this.getMerchandises()
      }
    },
  },
  computed: {
    // 偏好 > 商品小卡價格呈現
    merchCardShowPriceMode() {
      let merchCard = 'normal'
      this.$store.state.Prefer.p_shop.merchCard.forEach(item => {
        if (item.selected) merchCard = item.value
      })
      return merchCard
    },
    // 偏好 > 熱賣商品
    merchCardShowSellQuantity() {
      let sellQuantity = 'doNotShow'
      this.$store.state.Prefer.p_shop.sellQuantity.forEach(item => {
        if (item.selected) sellQuantity = item.value
      })
      return sellQuantity
    },
    // 偏好 > 顯示到貨時間
    merchCardShowArrivalTime() {
      return this.$store.state.Prefer.p_shop.showArrivalTime[0].selected
    },
    // 偏好 > 顯示庫存
    showStock() {
      return this.$store.state.Prefer.p_shop.showStock[0].selected
    },
  },
  methods: {
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得 participantInfo
    getParticipantInfo() {
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          const participantInfo = res.data
          this.participantInfo = participantInfo
          // 檢查此顧客是否有權限進入
          if (this.participantInfo.deleted || this.participantInfo.forbidden) {
            alert('您尚未有權限進入賣場，可能被賣家刪除或禁止')
            return this.$router.push({name: 'C_Overview'})
          }
          this.getStoreInfo()
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
        type: 5,
        ids: [this.storeId],
        methods: '{getStorePictureLinks,getMerchClassifications{getCustomerGroups}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            storeInfo.storePictureLinks.forEach(img => { img.imgUrl = handleImageHash2Url(img.imageHash) })
            vm.pictureLinks = storeInfo.storePictureLinks
            // 檢查是否關閉個人賣場
            if (!storeInfo.opened) {
              vm.$methods.switchLoading('hide')
              alert('賣場已關閉')
              return vm.$router.push({name: 'C_Overview'})
            }
            vm.sortOutCategories()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理商品分類
    sortOutCategories() {
      this.categories = []
      const now = new Date() * 1
      const p_customerGroupId = this.participantInfo.customerGroupId
      const p_storeVipId = this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0
      this.storeInfo.merchClassifications.forEach(item => {
        const openInterval = item.openInterval
        const enableTime = item.enableTime
        const expirationTime = item.expirationTime
        const categroyVipId = item.storeVipId ? item.storeVipId : 0
        const hidden = item.hidden
        let matchTheTimeInterval = true
        if (openInterval && enableTime && expirationTime) {
          matchTheTimeInterval = enableTime <= now && now <= expirationTime
        }
        let matchCustomerGroup = true
        if (p_customerGroupId && item.customerGroups.length > 0) {
          matchCustomerGroup = item.customerGroups.some(group => {
            return group.id === p_customerGroupId
          })
        } else if (!p_customerGroupId && item.customerGroups.length > 0) {
          matchCustomerGroup = false
        }
        let matchVipId = p_storeVipId >= categroyVipId
        console.log(matchTheTimeInterval,matchCustomerGroup,matchVipId,!hidden)
        if (this.participantInfo.authority === 'CUSTOMER') {
          if (matchTheTimeInterval && matchCustomerGroup && matchVipId && !hidden) this.categories.push(item)
        } else {
          if (matchTheTimeInterval && !hidden) this.categories.push(item)
        }
      });
      // 檢查 query 是否有分類 ID
      this.checkQuery()
    },
    // 檢查 query 是否有分類 ID
    checkQuery() {
      if (!this.$route.query.category) {
        // 路由沒有分類 ID
        if (this.categories.length > 0) {
          this.currentCategoryId = this.categories[0].id
          this.currentCategoryName = this.categories[0].name
          this.currentCategorySubtitle = this.categories[0].subtitle
          this.currentCategoryBanner = this.categories[0].imageHash ? this.categories[0].imageHash : null
        } else {
          this.currentCategoryId = 0
          this.getMerchandises()
        }
        this.$router.replace(`/customer/store/${this.storeId}/shop?category=${this.currentCategoryId}`)
      } else {
        // 若路由已經有分類 ID
        if (parseInt(this.$route.query.category) > 0) {
          const hasCategoryId = this.categories.some(item => {
            if (item.id === parseInt(this.$route.query.category)) {
              this.currentCategoryId = item.id
              this.currentCategoryName = item.name
              this.currentCategorySubtitle = item.subtitle
              this.currentCategoryBanner = item.imageHash ? item.imageHash : null
              return true
            }
          })
          // 如果 ID 已經不存在的話，就預設抓未分類
          if (!hasCategoryId) {
            this.currentCategoryId = 0
            this.getMerchandises()
            this.$router.replace(`/customer/store/${this.storeId}/shop?category=0`)
          }
        } else if (parseInt(this.$route.query.category) === 0) {
          this.getMerchandises()
        } else if (parseInt(this.$route.query.category) === -1) {
          // 如果原本為搜尋，然後重新整理，就預設為未分類
            this.currentCategoryId = 0
            this.getMerchandises()
            this.$router.replace(`/customer/store/${this.storeId}/shop?category=0`)
        }
      }
    },
    // 切換分類
    changeCategory(category) {
      this.breakOff()
      if (category === 0) {
        this.currentCategoryId = 0
        this.currentCategoryName = '未分類'
        this.currentCategorySubtitle = ''
        this.currentCategoryBanner = null
        this.$router.replace(`/customer/store/${this.storeId}/shop?category=0`)
      } else {
        this.currentCategoryId = category.id
        this.currentCategoryName = category.name
        this.currentCategorySubtitle = category.subtitle
        this.currentCategoryBanner = category.imageHash ? category.imageHash : null
        this.$router.replace(`/customer/store/${this.storeId}/shop?category=${category.id}`)
      }
    },
    // 抓取商品資料
    getMerchandises(state) {
      this.areaLoading = true
      this.$methods.switchLoading('show')
      const vm = this
      const getMerchandisesApi = `${process.env.VUE_APP_API}/merchandise/customerStoreData`
      const header = {
        authorization: this.serverToken
      }
      const data = {
        'storeId': this.storeId,
        'merchClassificationId': this.currentCategoryId,
        'identity': this.participantInfo.authority,
        'vipId': this.participantInfo.storeVipId ? this.participantInfo.storeVipId : 0,
        'priceDisplayState': this.merchCardShowPriceMode == 'normal' ? 'normal' : 'discount'
      }
      // 搜尋商品 (不分商品分類，全部的商品進行搜尋)
      if (state === 'search') {
        if (!this.search) {
          this.areaLoading = false
          this.$methods.switchLoading('hide')
          return this.SweetAlert('other', '您尚未輸入商品名稱')
        }
        this.removeClass()
        this.$router.push(`/customer/store/${vm.storeId}/shop?category=-1`)
        this.currentCategoryId = -1
        this.currentCategoryName = '搜尋商品'
        this.currentCategorySubtitle = ''
        this.currentCategoryBanner = null
        delete data.merchClassificationId
        data.name = this.search
      }
      this.getMerchandisesFn = $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandisesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseList = res.data
            vm.sortOutMerchandises(merchandiseList)
          }
        },
        error: function(err) {
          console.log(err)
          if (!err.responseJSON) return
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理商品資料
    sortOutMerchandises(merchandiseList) {
      this.sortOutMerchandisesFn = setTimeout(() => {
        // 整理商品列表
        this.merchandiseList = []
        let queryMerchIdIsInThisClassified = 0
        merchandiseList.forEach(item => {
          if (this.$route.query && this.$route.query.merchandiseId && this.$route.query.merchandiseId == item.id) queryMerchIdIsInThisClassified = item.id
          const object = {
            id : item.id,
            deadLineTime : item.deadLineTime ? new Date(item.deadLineTime).getTime() : null,
            frontImgHash : item.frontImg ? item.frontImg : null,
            sellState : item.sellState ? item.sellState : null,
            name : item.name ? item.name : null,
            label : item.label ? item.label : null,
            lowerPrice : item.lowerPrice,
            higherPrice : item.higherPrice,
            startSaleQuantity : item.startSaleQuantity ? item.startSaleQuantity : null,
            remainingStock : item.remainingStock ? item.remainingStock : 0,
            hotQuantity : item.hotQuantity ? item.hotQuantity : null,
            arrivalTime :  item.arrivalTime ? this.$methods.moment(item.arrivalTime).format("YYYY-MM-DD HH:mm:ss") : null
          }
          this.merchandiseList.push(object)
        })
        console.log(merchandiseList);

        this.areaLoading = false
        this.$methods.switchLoading('hide')
        // 移動到指定商品
        let checkTimes = 0
        this.moveToSpecifiedLocation(checkTimes, queryMerchIdIsInThisClassified)
      },100)
    },
    // 移動到指定商品
    moveToSpecifiedLocation(checkTimes, queryMerchIdIsInThisClassified) {
      setTimeout(() => {
        // 若確定商品 ID 存在在此分類內 (queryMerchIdIsInThisClassified > 0) 在執行
        if (this.$route.query && this.$route.query.merchandiseId && checkTimes <= 2 && queryMerchIdIsInThisClassified > 0) {
          if (!document.getElementById(`Merchandise${this.$route.query.merchandiseId}`)) {
            checkTimes ++
            this.moveToSpecifiedLocation(checkTimes, queryMerchIdIsInThisClassified)
            return
          }
          document.getElementById(`Merchandise${this.$route.query.merchandiseId}`).scrollIntoView({
            behavior: 'smooth'
          })
        }
      }, 500)
    },
    // 輪播圖點擊前往連結
    carouselUrl(data) {
      if (data.url) window.open(data.url)
    },
    // 前往下單
    goToOrder(data) {
      this.$router.push({
        name: 'C_OrderPage',
        query: {
          merchandiseId: data.id,
        },
      })
    },
    // * 螢幕寬度 <= 919px 時分類列表的操作 (start)
    // <= 768 時，需要點選分類標籤將分類列表打開
    showCategoryList() {
      $('.category-block').addClass('d-block')
    },
    removeClass() {
      const remove = $('#CategoryBlock').attr('class').split(' ').some(item => {
        return item === 'd-block'
      })
      if (remove) $('#CategoryBlock').removeClass('d-block')
    },
    // * 螢幕寬度 <= 768 時分類列表的操作 (end)
    // 中斷 "取商品" 與 "整理商品"
    breakOff() {
      if (this.getMerchandisesFn) this.getMerchandisesFn.abort() // 暫停向後端取資料的請求。
      if (this.sortOutMerchandisesFn) clearTimeout(this.sortOutMerchandisesFn) // 暫停整理商品資料動作。
    }
  }
}
</script>