<template>
  <!-- 倒數計時器 -->
  <div>
    <span @click.stop="switchShowMode">
      <span v-if="!showFullTime">{{ timeLeft }}</span>
      <span v-else>{{
        $methods.moment(time).format("YYYY-MM-DD HH:mm:ss")
      }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    /**
     * 傳入格式為 timestamp
     */
    time: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      timeLeft: '',
      showFullTime: false,
    }
  },
  mounted() {
    this.count()
  },
  unmounted() {
    clearInterval(this.point)
  },
  methods: {
    // 計算倒數
    count() {
      const oneDayToMilliseconds = 24 * 60 * 60 * 1000 // 一天的毫秒數
      const oneHourToMilliseconds = 60 * 60 * 1000 // 一小時的毫秒數
      const oneMinToMilliseconds = 60 * 1000 // 一分鐘的毫秒數
      const oneSecToMilliseconds = 1000 // 一秒的毫秒數
      this.point = setInterval(() => {
        const now = new Date() * 1
        let millisecondsRemaining = (this.time - now) // 剩餘毫秒數
        // console.log(`ID: ${this.id}`)
        millisecondsRemaining -= 1000 // 剩餘毫秒數，經過一秒就減去一秒
        // 剩餘天數
        let daysRemaining = this.$methods.numberToFixed(millisecondsRemaining / oneDayToMilliseconds, 0)
        if (daysRemaining > 0) return this.timeLeft = `倒數 ${daysRemaining} 天`
        // 剩餘小時數
        let hoursRemaining = this.$methods.numberToFixed(millisecondsRemaining / oneHourToMilliseconds, 0)
        if (hoursRemaining > 1) return this.timeLeft = `倒數 ${hoursRemaining} 小時`
        // 剩餘分鐘數
        let minRemaining = this.$methods.numberToFixed(millisecondsRemaining / oneMinToMilliseconds, 0)
        if (minRemaining > 1) return this.timeLeft = `倒數 ${minRemaining} 分鐘`
        // 剩餘秒數
        let secRemaining = this.$methods.numberToFixed(millisecondsRemaining / oneSecToMilliseconds, 0)
        if (secRemaining > 0) return this.timeLeft = `倒數 ${secRemaining} 秒`
        // 時間到
        this.timeLeft = '收單時間到'
        clearInterval(point)
      }, 1000)
    },
    // 切換顯示模式
    switchShowMode() {
      this.showFullTime = !this.showFullTime
    },
  },
}
</script>